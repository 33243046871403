import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/seo"

export default function NotFound() {
  return (
    <Layout>
      <Seo title="Page Not Found" description="Error 404 page." />
      <section className="hero px-4 bg-brand-blue flex items-center">
        <div className="container mx-auto">
          <div className="hero__content text-center">
            <h1 className="text-white pb-4 relative">
              Page Not Found
              <span className="border-b-2 border-brand-orange inline-block h-1 w-24 absolute bottom-0 left-1/2 transform -translate-x-1/2"></span>
            </h1>
          </div>
        </div>
      </section>
      <section className="px-4 py-24">
        <div className="container mx-auto">
          <p>Sorry, the page you are looking for could not be found.</p>
        </div>
      </section>
    </Layout>
  )
}
